export function getEventById(id) {
    return new Promise((resolve, reject) => {
        
        fetch(`${process.env.REACT_APP_API_URL}/store_api/events/${id}`)
            
            .then(async (res) => {
                
                if (res.status !== 200) {
                    reject()
                    return;
                }

                const e = await res.json();

                const event = {
                    eventId: id,
                    hostId: e.host_id,
                    hostName: e.host_name,
                    chargesEnabled: e.charges_enabled,
                    title: e.event_title,
                    description: e.event_desc,
                    imageUrl: e.image_url,
                    venue: e.address1,
                    address: e.address2,
                    city: e.city,
                    province: e.prov_state,
                    country: e.country,
                    postalCode: e.postal_code,
                    finePrint: e.fine_print,
                    startDate: e.start_date,
                    endDate: e.end_date,
                    timezone: e.time_zone,
                    concluded: e.concluded,
                    ctaDescription: e.cta_desc,
                    ctaButtonText: e.cta_btn_text,
                    ctaButtonURL: e.cta_btn_url,
                    hostPricingId: e.host_pricing_id,
                    taxable: e.taxable_products,
                    taxIncluded: e.taxes_included_in_product,
                    websiteUrl: e.url_website,
                    facebookUrl: e.url_facebook,
                    twitterUrl: e.url_twitter,
                    instagramUrl: e.url_instagram,
                    tiktokUrl: e.url_tiktok,
                    linkedinUrl: e.url_linkedin,
                    metaPixelId: e.meta_pixel_id,
                    hostAbsorbsB4tFees: e.host_absorbs_b4t_fees,
                    hostAbsorbsCcFees: e.host_absorbs_cc_fees,
                    checkoutQuestionMode: e.checkout_question_mode,
                    fees: {
                        feePerItem: e.b4t_fee_line_item_amt,
                        percentFeePerOrder: e.b4t_fee_order_pct,
                        feePerOrder: e.b4t_fee_order_amt,
                        feeMinAmount: e.b4t_fee_min_amt,
                        feeMaxAmount: e.b4t_fee_max_amt,
                        ccPercent: e.cc_fee_order_pct,
                        ccFlatFee: e.cc_fee_order_amt,
                        taxName: e.tax_name,
                        taxRate: e.tax_rate,
                        taxableB4tFees: e.taxable_b4t_fees,
                        taxableCcFees: e.taxable_cc_fees
                    }
                }

                resolve(event)
            })

        .catch((e) => {
            reject(e)
        })
    })
}

export async function getProductsByEventId(id, options) {
    const codes = options?.codes;
    let url = `${process.env.REACT_APP_API_URL}/store_api/events/${id}/product_page`;

    if (codes) {
        url += '?access=' + codes.map(c => encodeURIComponent(c)).join();
    }

    const response = await fetch(url, { headers: { 'Content-Type': 'application/json' } });

    if (response.status !== 200) {
        throw new Error();
    }

    const data = await response.json();

    return {
        bundles: data.bundles,
        products: data.products
    }
}

export function getAllUpcomingEvents(id) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/events`)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Non 200 response');
                } else {
                    return res.json()
                }
            })
            .then(data => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function getEventsByHostId(id) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/host/${id}/events`)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Non 200 response');
                } else {
                    return res.json()
                }
            })
            .then(data => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function getHostById(id) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/host/${id}`)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error('Non 200 response');
                } else {
                    return res.json()
                }
            })
            .then(data => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function postUserAndPayment(body) {
	return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/purchases`, {
           method: 'POST',
           headers: { "Content-Type": "application/json" },
           body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(data => {
            if (!data) throw new Error();
            resolve(data);
        })
        .catch((e) => {
            reject(e)
        })
    })
}

export function getDiscountByCode(code, event) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/events/${event}/discount/${encodeURIComponent(code)}`)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    resolve({
                        code:       code,
                        status:     data.status,
                        percent:    data.discount_percent,
                        amount:     data.discount_amount,
                        products:   data.disc_products || [],
                        productMax: data.product_max,
                    })
                } else {
                    throw new Error('Unexpected response code received');
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function downloadTickets(tranId, email, last4, locale) {
	return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/orders/${tranId}/tickets?email=${encodeURIComponent(email)}&last4=${last4}&locale=${locale}`)
            .then(res => res.text())
            .then(tickets => {
                if (!tickets) throw new Error();
                resolve(tickets);
            })
            .catch((e) => {
                reject(e)
            })
    })
}

export function lookupOrdersForCustomer(email, last4) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/orders`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, last4 })
         })
            .then(async res => {
                if (res.status !== 200) throw new Error();

                const orders = await res.json();
                resolve(orders);
            })
            .catch((e) => {
                reject(e)
            })
    })
}

export function createTicketLog(tranId, typeId, email) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/apiv2/ticket_activity`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ tranId, typeId, email })
         })
            .then(async res => {
                if (res.status !== 200) throw new Error();
                resolve();
            })
            .catch((e) => {
                reject(e)
            })
    })
}


export async function verifyAccessCode(code, eventId) {
    // Check for access code under the given event.
    // Endpoint will indicate if this is a valid access_code.
    const response = await fetch(`${process.env.REACT_APP_API_URL}/store_api/events/${eventId}/access_code/${code}`);

    if (response.status === 200) {
        const data = await response.json();
        return {
            valid: data.valid,
            productCount: data.productCount
        }
    } else {
        throw new Error('server responded with non 200 status code')
    }
}